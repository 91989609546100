import React from 'react';

function Product(){
    return (
        <div>
            <h1>
                Stay tuned, we're still conducting pricing tests!
            </h1>
        </div>
    );
};

export default Product;